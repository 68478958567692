<template>
<div class="main">
    <div class="search_bar">
        <el-input placeholder="请输入内容" v-model="input">
            <el-button class="search_btn" slot="append" icon="el-icon-search" @click="search">
            </el-button>
        </el-input>
    </div>
    <div class="label_bar">
        <p>&nbsp;&nbsp;</p>
        <div class="label" v-bind:class="{ active: isActive == -1 }" @click="all">
            All
        </div>
        <div class="label" v-for="(label, index) in labels" :key="index" v-bind:class="{ active: index == isActive }" @click="checkItem(index)">
            {{ label }}
        </div>
    </div>
    <div class="content">
        <el-collapse class="contentList" accordion v-for="(blog, index) in blogs" :key="index">
            <el-collapse-item>
                <template slot="title">
                    <p>&nbsp;&nbsp;</p>
                    {{ blog.title }}
                </template>
                <div @click="detail(blog._id)" class="abstract">
                    <p>&nbsp;&nbsp;</p>
                    {{ blog.abstract }}
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</div>
</template>

<script>
import url from "@/serviceAPI.config.js";
import axios from "axios";

export default {
    data() {
        return {
            isActive: -1,
            input: "",
            data: [],
            labels: [],
            blogs: [],
        };
    },
    methods: {
        async getdata() {
            let data = {};
            await axios({
                    url: url.blog,
                    method: "get",
                })
                .then((response) => {
                    data = response.data.data;
                    this.data = data;
                    this.blogs = data;
                })
                .catch((error) => {
                    console.log(error);
                    // ！！！出现错误怎么优化，暂时用刷新
                    // location.reload();
                    // 重新调用函数
                    this.getdata();
                });
            let s = new Set();
            for (let i = 0; i < data.length; i++) {
                s.add(data[i].target);
            }
            this.labels = Array.from(s);
            console.log(this.labels);
        },
        checkItem(index) {
            this.isActive = index;
            // 选择对应label的blog
            this.blogs = [];
            for (let i = 0; i < this.data.length; i++) {
                if (this.labels[index] == this.data[i].target) {
                    this.blogs.push(this.data[i]);
                }
            }
            console.log(this.blogs);
        },
        all() {
            this.isActive = -1;
            this.blogs = this.data;
            console.log(this.blogs);
        },
        detail(id) {
            this.$router.push({
                path: "/blogdetail",
                query: {
                    id: id,
                },
            });
        },
        search(){
            this.blogs = [];
            this.isActive = -2;
            for (let i = 0; i < this.data.length; i++) {
                if(this.data[i].title.search(this.input) != -1){
                    this.blogs.push(this.data[i]);
                    console.log(222)
                }
                else if (this.data[i].content.search(this.input) != -1) {
                    this.blogs.push(this.data[i]);
                    console.log(111)
                    // break;
                }
                else if(this.data[i].content.search(this.input) != -1){
                    this.blogs.push(this.data[i]);
                     console.log(333)
                }
            }
            console.log(this.blogs);
        }
    },
    created: function () {
        this.getdata();
    },
};
</script>

<style scoped>
.main {
    background-color: #f4f4f538;
}

.search_bar {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 90vw;
    padding: 10px 5vw;
    border-bottom: 1px solid #f7f3f3;
}

.label_bar {
    display: flex;
    flex-direction: row;
    padding: 61px 5vw 0 5vw;
    background-color: rgb(141 177 249 / 36%);
    width: 90vw;
    height: 30px;
    overflow: scroll;
    border-bottom: 1px solid #f7f3f3;
}

.label_bar::-webkit-scrollbar {
    display: none;
}

.label {
    /* font-size: 6px; */
    margin-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    word-break: keep-all;
    /* 不换行 */
    white-space: nowrap;
    /* 不换行 */
    cursor: pointer;
}

 .active {
    color: #0071e3;
}

.content {
    padding: 0 5vw;
}

.contentList {
    margin: 10px 0px;
    background-color: #c0c4cc;
}

.abstract {
    display: flex;
    word-break: break-all;
    text-align: left;
    justify-content: flex-start;

}
</style>
